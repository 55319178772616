import { fetchAllSalon, fetchProvince } from 'actions/salon';
import React, { FC, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { notification } from 'antd';
import iconPhone from '../../images/icons/phone.svg';
import iconNav from '../../images/icons/navigate.svg';
import iconArrowRight from '../../images/fillWhite/arrowRight.svg';

const FooterLayoutV2: React.FC<{
    trackingData: any;
    data: any;
    actions: any;
    phone: string;
}> = ({ phone, trackingData, data, actions }) => {
    const { listSalon, listProvince } = data;
    const [listProvinceNew, setListProvinceNew] = useState([]);

    const openDirect = (map: string) => {
        if (map) {
            window.open(map, '_blank');
            window.focus();
        } else {
            const config = {
                message: 'Thông Báo',
                description: 'Salon đang cập nhập tính năng chỉ đường!',
                duration: 3,
                className: 'error',
            };
            notification.error(config);
        }
    };

    const formatPhone = (textPhone: string) => textPhone.split('.').join('');

    const menuBlock = [
        { title: 'Chính sách bảo mật', path: '/chinh-sach-bao-mat' },
        {
            title: 'Điều kiện giao dịch chung',
            path: '/dieu-kien-giao-dich-chung',
        },
        { title: 'Hotline (1000đ/phút): 1900.299.993', path: 'tel:1900299993' },
    ];

    const getProvince = async () => {
        if (listProvince) {
            const listNew = await actions.fetchProvince();
            return Promise.resolve(listNew);
        }
        return Promise.resolve(listProvince);
    };

    const getSalon = async () => {
        if (listSalon.length === 0) {
            const listSalonApi = await actions.fetchAllSalon('');
            return Promise.resolve(listSalonApi);
        }
        return Promise.resolve(listSalon);
    };

    useEffect(() => {
        getProvince();
        getSalon();
    }, []);

    useEffect(() => {
        if (listProvince) {
            const data = listProvince?.data?.filter(
                (item: any) =>
                    item.totalSalon > 0 &&
                    item.cityId != 1 &&
                    item.cityId != 62,
            );
            setListProvinceNew(data);
        }
    }, [listProvince]);

    console.log(listSalon, 'listSalon');
    return (
        <div className="bg-[#15397f] pt-6  items-center justify-center text-white text-sm flex flex-col ">
            <div className="flex flex-col items-center justify-center w-full pb-10">
                <div className="text-xl font-bold uppercase">
                    Hệ thống salon Moyo
                </div>
                <div className="w-[85%] max-md:w-[90%] mt-6">
                    <div className="flex flex-col items-center justify-center">
                        <div className="text-center font-semibold text-base bg-[#EF4E24] rounded-md w-max py-1 px-2">
                            Moyo Hồ Chí Minh
                        </div>
                        <div className="grid grid-cols-4 gap-4 mt-2 max-md:grid-cols-1 max-xl:grid-cols-3">
                            {listSalon
                                .filter((item: any) => item.cityId === 1)
                                .map((salon: any) => (
                                    <div>
                                        <div className="flex flex-row items-start h-full">
                                            <div className="w-[10%]">
                                                <img
                                                    src="/icon-footer.svg"
                                                    alt=""
                                                    className="w-max"
                                                />
                                            </div>
                                            <span className="w-[90%] flex flex-col justify-between h-full">
                                                <span>{salon.addressNew}</span>
                                                <div className="flex flex-row justify-start text-[10px] gap-1 font-semibold pt-1">
                                                    <div className="">
                                                        <a
                                                            href={`tel:${formatPhone(salon.phone)}`}
                                                        >
                                                            <div className="rounded-sm text-black gap-1 flex flex-row bg-white px-1 justify-center items-center">
                                                                <img
                                                                    className="w-[14px]"
                                                                    src={iconPhone}
                                                                    alt=""
                                                                />
                                                                <span>HOTLINE</span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div
                                                        onClick={() => openDirect(salon.map)}
                                                        className="rounded-sm flex flex-row gap-1 bg-white px-1 justify-center items-center cursor-pointer"
                                                    >
                                                        <img className="w-[14px]" src={iconNav} alt="" />
                                                        <span className="text-black ">
                                                            Chỉ đường
                                                        </span>
                                                    </div>
                                                    <Link
                                                        to={`/booking?salonId=${salon.id}&phone=0&step=0`}
                                                        className="rounded-sm flex flex-row bg-[#EF4E24] px-1 justify-center items-center gap-1"
                                                        title="Đặt lịch ngay"
                                                    >
                                                        <span className='text-white font-semibold justify-center flex items-center'>Đặt lịch ngay</span>
                                                        <img className="w-[14px]" src={iconArrowRight} alt="" />
                                                    </Link>
                                                </div>
                                            </span>
                                        </div>

                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center mt-10">
                        <div className="text-center font-semibold text-base bg-[#EF4E24] rounded-md w-max py-1 px-2">
                            Moyo Hà Nội
                        </div>
                        <div className="grid grid-cols-4 gap-4 mt-2 max-md:grid-cols-1 max-xl:grid-cols-3">
                            {listSalon
                                .filter((item: any) => item.cityId === 62)
                                .map((salon: any) => (
                                    <div className="flex flex-row items-start">
                                        <div className=" w-[10%]">
                                            <img
                                                src="/icon-footer.svg"
                                                alt=""
                                                className="w-max"
                                            />
                                        </div>
                                        <span className="w-[90%] flex flex-col justify-between h-full">
                                            <span>{salon.addressNew}</span>
                                            <div className="flex flex-row justify-start text-[10px] gap-1 font-semibold pt-1">
                                                <div className="">
                                                    <a
                                                        href={`tel:${formatPhone(salon.phone)}`}
                                                    >
                                                        <div className="rounded-sm text-black gap-1 flex flex-row bg-white px-1 justify-center items-center">
                                                            <img
                                                                className="w-[14px]"
                                                                src={iconPhone}
                                                                alt=""
                                                            />
                                                            <span>HOTLINE</span>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div
                                                    onClick={() => openDirect(salon.map)}
                                                    className="rounded-sm flex flex-row gap-1 bg-white px-1 justify-center items-center cursor-pointer"
                                                >
                                                    <img className="w-[14px]" src={iconNav} alt="" />
                                                    <span className="text-black ">
                                                        Chỉ đường
                                                    </span>
                                                </div>
                                                <Link
                                                    to={`/booking?salonId=${salon.id}&phone=0&step=0`}
                                                    className="rounded-sm flex flex-row bg-[#EF4E24] px-1 justify-center items-center gap-1"
                                                    title="Đặt lịch ngay"
                                                >
                                                    <span className='text-white font-semibold justify-center flex items-center'>Đặt lịch ngay</span>
                                                    <img className="w-[14px]" src={iconArrowRight} alt="" />
                                                </Link>
                                            </div>
                                        </span>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-10 mt-10 max-md:grid-cols-1 max-xl:grid-cols-3">
                        {listProvinceNew.map((city: any) => {
                            const addresses = listSalon.filter(
                                (item: any) => item.cityId === city.cityId,
                            );
                            return addresses.length > 0 ? (
                                <div className="flex flex-col items-center">
                                    <div className="text-center font-semibold text-base bg-[#EF4E24] rounded-md w-max py-1 px-2">
                                        Moyo {city.cityName}
                                    </div>
                                    <div className="grid w-full grid-cols-1 gap-4 mt-2">
                                        {addresses.map((salon: any) => (
                                            <div className="flex flex-row items-start">
                                                <div className=" w-[10%]">
                                                    <img
                                                        src="/icon-footer.svg"
                                                        alt=""
                                                        className="w-max"
                                                    />
                                                </div>
                                                <span className="w-[90%] flex flex-col justify-between h-full">
                                                    <span>{salon.addressNew}</span>
                                                    <div className="flex flex-row justify-start text-[10px] gap-1 font-semibold pt-1">
                                                        <div className="">
                                                            <a
                                                                href={`tel:${formatPhone(salon.phone)}`}
                                                            >
                                                                <div className="rounded-sm text-black gap-1 flex flex-row bg-white px-1 justify-center items-center">
                                                                    <img
                                                                        className="w-[14px]"
                                                                        src={iconPhone}
                                                                        alt=""
                                                                    />
                                                                    <span>HOTLINE</span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div
                                                            onClick={() => openDirect(salon.map)}
                                                            className="rounded-sm flex flex-row gap-1 bg-white px-1 justify-center items-center cursor-pointer"
                                                        >
                                                            <img className="w-[14px]" src={iconNav} alt="" />
                                                            <span className="text-black ">
                                                                Chỉ đường
                                                            </span>
                                                        </div>
                                                        <Link
                                                            to={`/booking?salonId=${salon.id}&phone=0&step=0`}
                                                            className="rounded-sm flex flex-row bg-[#EF4E24] px-1 justify-center items-center gap-1"
                                                            title="Đặt lịch ngay"
                                                        >
                                                            <span className='text-white font-semibold justify-center flex items-center'>Đặt lịch ngay</span>
                                                            <img className="w-[14px]" src={iconArrowRight} alt="" />
                                                        </Link>
                                                    </div>
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null;
                        })}
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center w-full text-black bg-gray-200 max-md:py-4 ">
                <div className="w-[80%] grid grid-cols-4 gap-2 max-md:grid-cols-1 max-xl:grid-cols-2 max-md:gap-2">
                    {menuBlock.map((item: any, index: number) => {
                        return (
                            <div className="flex flex-row items-center justify-center max-md:justify-start">
                                <a
                                    href={item.path}
                                    className=" pr-1 text-black"
                                >
                                    {item.title}
                                </a>
                                <svg
                                    width="9"
                                    height="8"
                                    viewBox="0 0 9 8"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M1.58918 0.165895C1.40443 0.165895 1.22724 0.239286 1.09661 0.369924C0.96597 0.500561 0.892578 0.677744 0.892578 0.862493V7.13188C0.892578 7.31663 0.96597 7.49381 1.09661 7.62445C1.22724 7.75509 1.40443 7.82848 1.58918 7.82848H7.85856C8.04331 7.82848 8.2205 7.75509 8.35113 7.62445C8.48177 7.49381 8.55516 7.31663 8.55516 7.13188V4.69379C8.55516 4.60141 8.51847 4.51282 8.45315 4.4475C8.38783 4.38218 8.29924 4.34549 8.20686 4.34549C8.11449 4.34549 8.0259 4.38218 7.96058 4.4475C7.89526 4.51282 7.85856 4.60141 7.85856 4.69379V7.13188H1.58918V0.862493H4.02727C4.11965 0.862493 4.20824 0.825798 4.27356 0.760479C4.33888 0.69516 4.37557 0.606569 4.37557 0.514194C4.37557 0.421819 4.33888 0.333228 4.27356 0.267909C4.20824 0.202591 4.11965 0.165895 4.02727 0.165895H1.58918ZM8.45346 0.267598C8.51823 0.332531 8.55478 0.420391 8.55516 0.512104V2.60399C8.55516 2.69636 8.51847 2.78496 8.45315 2.85027C8.38783 2.91559 8.29924 2.95229 8.20686 2.95229C8.11449 2.95229 8.0259 2.91559 7.96058 2.85027C7.89526 2.78496 7.85856 2.69636 7.85856 2.60399V1.35499L4.27387 4.94038C4.24148 4.97276 4.20304 4.99845 4.16073 5.01598C4.11842 5.0335 4.07307 5.04252 4.02727 5.04252C3.98147 5.04252 3.93613 5.0335 3.89381 5.01598C3.8515 4.99845 3.81306 4.97276 3.78068 4.94038C3.74829 4.908 3.7226 4.86955 3.70508 4.82724C3.68755 4.78493 3.67853 4.73958 3.67853 4.69379C3.67853 4.64799 3.68755 4.60264 3.70508 4.56033C3.7226 4.51802 3.74829 4.47957 3.78068 4.44719L7.36607 0.862493H6.11707C6.02469 0.862493 5.9361 0.825798 5.87078 0.760479C5.80546 0.69516 5.76877 0.606569 5.76877 0.514194C5.76877 0.421819 5.80546 0.333228 5.87078 0.267909C5.9361 0.202591 6.02469 0.165895 6.11707 0.165895H8.20686C8.25264 0.165775 8.29799 0.174698 8.3403 0.192151C8.38262 0.209604 8.42108 0.235244 8.45346 0.267598Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </div>
                        );
                    })}
                    <div className="flex flex-row items-center justify-center max-md:justify-start">
                        Giờ phục vụ: Thứ 2 đến Chủ Nhật, 8h30 - 20h30
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center w-full py-3 text-black bg-gray-200 max-md:pb-4">
                © 2015 Công Ty Cổ Phần TMDV 30Shine / Địa chỉ: 82 Trần Đại
                Nghĩa, P. Đồng Tâm, Q. Hai Bà Trưng, HN
                <span className="md:whitespace-nowrap">
                    / GPĐKKD số 010.7467.693 do Sở KHĐT TP.HN cấp ngày
                    08/06/2016.
                </span>
            </div>
        </div>
    );
};

// export default FooterLayoutV2;
function mapStateToProps(state: any) {
    return {
        trackingData: state.trackingData,
        phone: state.user.phoneNumber,
        data: {
            listProvince: state.salon.listProvince,
            listSalon: state.salon.listSalon,
            topSalon: state.salon.topSalon,
        },
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        actions: {
            ...bindActionCreators(
                {
                    fetchProvince,
                    fetchAllSalon,
                },
                dispatch,
            ),
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterLayoutV2);
