import React, { useState } from 'react';
import iconVoucher from 'images/icons/icon_voucher.svg';
import iconRight from 'images/icons/icon_chevron_right_blue.svg';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import { IService } from '../../types/Service';

const CampaignFooter: React.FC<{
    gotoCampaignScreen: Function;
    chosenCampaign: CampaignType[];
    chosenService: IService[];
    phoneBooking: string;
    gotoCampaignDetail: Function;
}> = ({
    gotoCampaignScreen,
    chosenCampaign,
    chosenService,
    phoneBooking,
    gotoCampaignDetail,
}) => {
        const common = useSelector((state: any) => state.common);
        const [openModal, setOpenModal] = useState(false);
        const listChosenCampaign = chosenCampaign.filter((item) => item.valid);
        // const listChosenCampaignInValid = chosenCampaign.filter(
        //     (item) => !item.valid,
        // );

        const listChosenCampaignInValidCondition = chosenCampaign.filter(
            (item) => item.validCondition,
        );

        const onClick = () => {
            gotoCampaignScreen();
            // if (!user?.phoneNumber || user?.phoneNumber === phoneBooking) {
            //     gotoCampaignScreen();
            // } else {
            //     setOpenModal(true);
            // }
        };

        return (
            <>
                {listChosenCampaignInValidCondition.length ? (
                    <div className="text-xs bg-[#E9FFF2] text-[#11B14B] px-4 py-2 ">
                        Bạn có khuyến mãi{' '}
                        {listChosenCampaignInValidCondition.map((item, index) => (
                            <span
                                onClick={() => gotoCampaignDetail(item)}
                                key={item.campaignId}
                                className="font-semibold">
                                {index > 0 ? ', ' : ''}
                                {item.name}
                            </span>
                        ))}
                    </div>
                ) : null}
                {/* {listChosenCampaignInValid.length ? (
                <div className=" text-xs bg-[#FFF1F0] text-[#E54D3E] px-4 py-2">
                    {chosenService.length
                        ? 'Dịch vụ đã chọn chưa đủ điều kiện áp dụng ưu đãi'
                        : 'Vui lòng chọn dịch vụ để áp dụng ưu đãi'}{' '}
                    {listChosenCampaignInValid.map((item, index) => (
                        <span key={item.campaignId} className="font-semibold">
                            {index > 0 ? ', ' : ''}
                            {item.name}
                        </span>
                    ))}
                </div>
            ) : null} */}

                <div
                    className="px-4 pt-3 text-sm bg-white cursor-pointer"
                    onClick={() => onClick()}
                >
                    <div className="border-b border-gray-e8 flex justify-between pb-3 px-[3px] text-[#15397f]">
                        <div className="flex items-center">
                            <img src={iconVoucher} alt="" className="w-5 mr-2" />
                            {common.lang === 'vi'
                                ? 'Ưu đãi của bạn'
                                : 'Your voucher'}
                        </div>
                        <div className="flex items-center text-[#15397f] whitespace-nowrap">
                            {listChosenCampaign.length
                                ? common.lang === 'vi'
                                    ? `Đã chọn ${listChosenCampaign.length} ưu đãi`
                                    : `Selected ${listChosenCampaign.length} voucher`
                                : common.lang === 'vi'
                                    ? `Chọn ưu đãi`
                                    : `Select a voucher`}{' '}
                            <img src={iconRight} alt="" className="ml-1" />
                        </div>
                    </div>
                </div>
                <Modal
                    visible={openModal}
                    className="modal-checkbox modal-noti-chosencampaign"
                    onCancel={() => setOpenModal(false)}
                    width={480}
                    footer={null}
                    title="THÔNG BÁO"
                    centered
                >
                    <>
                        <div className="text-sm text-center">
                            {common.lang === 'vi'
                                ? 'Bạn vui lòng đăng nhập bằng số điện thoại đang đặt lịch'
                                : 'Please log in with the phone number associated with your booking'}{' '}
                            <b>{phoneBooking}</b>
                            <span className="whitespace-nowrap">
                                {common.lang === 'vi'
                                    ? 'để chọn ưu đãi.'
                                    : 'to select the promotional offer.'}
                            </span>
                        </div>
                    </>
                </Modal>
            </>
        );
    };

export default CampaignFooter;
