import React, { Suspense, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import Skeleton from 'antd/lib/skeleton/index';
import { connect } from 'react-redux';
import $ from 'jquery';
import Header from 'components/header/v3/HeaderV4';
import FooterNewLayout from 'components/footerNewLayout';
import MiddleWareRouter from 'components/MiddleWareRouter';
import { setCookieFunc } from 'components/func/Function';
import { useCookies } from 'react-cookie';
import { fetchUser } from '../actions/user';
import 'antd/lib/skeleton/style/css';
import Home from './home';
import BookingNew from './bookingNew';

const BookingNewSaveLocation = React.lazy(() =>
    import('./bookingNew/SaveLocation'),
);

const BookingNewDone = React.lazy(() => import('./bookingNew/BookingDone'));

const About = React.lazy(() => import('./about'));
const IntroduceTopupApp = React.lazy(() => import('./IntroduceTopupApp'));
const NewCustomerReward = React.lazy(() =>
    import('./InviteSpecialCustomer/checkInvite'),
);
const InviteCustomer = React.lazy(() =>
    import('./InviteSpecialCustomer/inviteCus'),
);
const Security = React.lazy(() => import('./security/Sevurity'));
const TradingConditions = React.lazy(() =>
    import('./security/TradingConditions'),
);
const SystemSalon = React.lazy(() => import('./systemSalon/v3'));
const SystemSalonItem = React.lazy(() => import('./systemSalon/v3/ItemSalon'));
const Page404 = React.lazy(() => import('./404'));
const ThuGian1 = React.lazy(() =>
    import('./landingpageService/dichVuThuGian/thuGian1'),
);
const ThuGian2 = React.lazy(() =>
    import('./landingpageService/dichVuThuGian/thuGian2'),
);
const ThuGian3 = React.lazy(() =>
    import('./landingpageService/dichVuThuGian/thuGian3'),
);
const ThuGian4 = React.lazy(() =>
    import('./landingpageService/dichVuThuGian/thuGian4'),
);
const ThuGian5 = React.lazy(() =>
    import('./landingpageService/dichVuThuGian/thuGian5'),
);
//
const HoiPhuc1 = React.lazy(() =>
    import('./landingpageService/dichVuHoiPhuc/hoiPhuc1'),
);
const HoiPhuc2 = React.lazy(() =>
    import('./landingpageService/dichVuHoiPhuc/hoiPhuc2'),
);
const HoiPhuc3 = React.lazy(() =>
    import('./landingpageService/dichVuHoiPhuc/hoiPhuc3'),
);
const HoiPhuc4 = React.lazy(() =>
    import('./landingpageService/dichVuHoiPhuc/hoiPhuc4'),
);

function WaitingComponentMaxWidth(Component) {
    return (props) => (
        <Suspense
            fallback={
                <div style={{ maxWidth: '1024px', margin: 'auto' }}>
                    <Skeleton paragraph={{ rows: 10 }} />
                </div>
            }
        >
            <Component {...props} />
        </Suspense>
    );
}

const Routes = (props) => {
    const [cookies, setCookie] = useCookies(['_cus_accessToken2']);

    useEffect(() => {
        if (cookies._cus_accessToken2) {
            window.localStorage.clear();
            localStorage.setItem('accessToken', cookies._cus_accessToken2);
        } else if (localStorage.getItem('accessToken')) {
            setCookieFunc(
                '_cus_accessToken2',
                localStorage.getItem('accessToken'),
                cookies,
                setCookie,
            );
        }

        props.fetchUser();
        $('html, body').animate({ scrollTop: 0 }, 500);
    }, []);

    return (
        <Router>
            <div id="content">
                <Header />
                <Switch>
                    <Route exact path="/" component={Home} />
                    {/* <Route exact path="/" component={HomeV2} /> */}
                    <Route
                        path="/booking/success"
                        component={WaitingComponentMaxWidth(BookingNewDone)}
                    />
                    <Route path="/booking" component={BookingNew} />

                    <Route
                        exact
                        path="/save-location"
                        component={WaitingComponentMaxWidth(
                            BookingNewSaveLocation,
                        )}
                    />
                    <MiddleWareRouter exact path="/about" component={About} />
                    <MiddleWareRouter
                        path="/gioi-thieu"
                        component={IntroduceTopupApp}
                        {...props}
                    />
                    <MiddleWareRouter
                        path="/gioi-thieu-ban-moi"
                        component={NewCustomerReward}
                        {...props}
                    />
                    <MiddleWareRouter
                        path="/moi-them-ban"
                        component={InviteCustomer}
                        {...props}
                    />
                    <MiddleWareRouter
                        exact
                        path="/chinh-sach-bao-mat"
                        component={Security}
                        {...props}
                    />
                    <MiddleWareRouter
                        exact
                        path="/dieu-kien-giao-dich-chung"
                        component={TradingConditions}
                    />
                    <MiddleWareRouter
                        exact
                        path="/system-salon"
                        component={SystemSalon}
                    />
                    <MiddleWareRouter
                        exact
                        path="/salons/:id"
                        component={SystemSalonItem}
                    />
                    <MiddleWareRouter
                        exact
                        path="/404"
                        component={Page404}
                        {...props}
                    />
                    {/* Dịch vụ thư giãn */}
                    <MiddleWareRouter
                        exact
                        path="/Goi-phong-moyo"
                        component={ThuGian1}
                        {...props}
                    />
                    <MiddleWareRouter
                        exact
                        path="/Goi-co-vai-gay"
                        component={ThuGian2}
                        {...props}
                    />
                    <MiddleWareRouter
                        exact
                        path="/Goi-co-vai-gay-shiatsu"
                        component={ThuGian3}
                        {...props}
                    />
                    <MiddleWareRouter
                        exact
                        path="/Goi-spa-da-mat-duong-sang"
                        component={ThuGian4}
                        {...props}
                    />
                    <MiddleWareRouter
                        exact
                        path="/Goi-spa-tang-sinh-collagen-vline"
                        component={ThuGian5}
                        {...props}
                    />
                    {/* Dịch vụ hồi phục */}
                    <MiddleWareRouter
                        exact
                        path="/Goi-detox"
                        component={HoiPhuc1}
                        {...props}
                    />
                    <MiddleWareRouter
                        exact
                        path="/Goi-detox-essence"
                        component={HoiPhuc2}
                        {...props}
                    />
                    <MiddleWareRouter
                        exact
                        path="/Goi-hap-nano-phuc-hoi"
                        component={HoiPhuc3}
                        {...props}
                    />
                    <MiddleWareRouter
                        exact
                        path="/Goi-nano-phuc-hoi-huyet-thanh"
                        component={HoiPhuc4}
                        {...props}
                    />
                    <Route render={() => <Redirect to="/" />} />
                </Switch>
                <FooterNewLayout />
            </div>
        </Router>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUser: () => dispatch(fetchUser()),
    };
};

export default connect(null, mapDispatchToProps)(Routes);
