import React, { useState } from 'react';
import {
    formatPriceServiceToNum,
    formatPriceWithKCharacter,
} from 'utils/BookingUltils';
import ListCampaignScreen from './ListCampaignScreen';
import CampaignDetailScreen from '../CampaignDetailScreen';
import { IService } from '../types/Service';

export const setChosenServiceWithCampaign = (
    listChosenService: IService[],
    listChosenCamp: CampaignType[],
    listService: IService[],
) => {
    const listServiceSelected = listChosenService.map((service) => {
        let newPrice = service.price;
        let newOldPrice = service.oldPrice;
        let newOldPriceNum = service.oldPriceNum;
        let newPriceNum = service.priceNum;
        let campaignIdApply = null;
        listChosenCamp.map((campaign) => {
            if (
                campaign.serviceApplies &&
                campaign.serviceApplies.length &&
                campaign.valid
            ) {
                campaign.serviceApplies.map((serviceCampaign) => {
                    if (service.serviceId === serviceCampaign.id) {
                        const priceService = service.priceNum;
                        if (priceService >= serviceCampaign.price) {
                            newPrice = formatPriceWithKCharacter(
                                serviceCampaign.price,
                            );
                            newOldPrice =
                                serviceCampaign.originPrice > 0
                                    ? formatPriceWithKCharacter(
                                          serviceCampaign.originPrice,
                                      )
                                    : service?.price || '0';
                            newOldPriceNum = serviceCampaign.originPrice;
                            newPriceNum = serviceCampaign.price;
                            if (campaign.valid)
                                campaignIdApply = campaign.campaignId;
                        }
                    }
                });
            }
        });
        // set lai gia dv khi khong co campaign ap dung
        if (!campaignIdApply) {
            const serviceElem = listService.find(
                (item) => item.serviceId === service.serviceId,
            );
            if (serviceElem) {
                newPrice = serviceElem.price;
                newOldPrice = serviceElem.oldPrice;

                newOldPriceNum = formatPriceServiceToNum(serviceElem.oldPrice);
                newPriceNum = formatPriceServiceToNum(serviceElem.price);
            }
        }
        return {
            ...service,
            price: newPrice,
            oldPrice: newOldPrice,
            priceNum: newPriceNum,
            oldPriceNum: newOldPriceNum,
            campaignId: campaignIdApply,
        };
    });
    return listServiceSelected;
};

const CampaignScreen: React.FC<{
    step: number;
    gotoCampaignDetail: Function;
    phone: string;
    salonId: number;
    chosenService: IService[];
    setChosenCampaign: Function;
    chosenCampaign: CampaignType[];
    onChangeChosenService: Function;
    handleGoToCampaignScreen: Function;
    listAllServices: IService[];
    listCampaign: ListCampaignType | null;
    setListCampaign: Function;
    campaignDetailSelected: CampaignType | null;
    setCampaignDetailSelected: Function;
}> = ({
    step,
    gotoCampaignDetail,
    phone,
    salonId,
    chosenService,
    setChosenCampaign,
    chosenCampaign,
    onChangeChosenService,
    handleGoToCampaignScreen,
    listCampaign,
    setListCampaign,
    listAllServices,
    campaignDetailSelected,
    setCampaignDetailSelected,
}) => {
    const [tabs, setTabs] = useState('1');

    const changeChosenServiceBooking = (
        listChosenCampaign: CampaignType[],
        chosenSv = chosenService,
    ) => {
        const newListChoSenService = setChosenServiceWithCampaign(
            chosenSv,
            listChosenCampaign,
            listAllServices,
        );
        onChangeChosenService(newListChoSenService);
    };

    const onClickSelectCampaign = (campaign: CampaignType) => {
        let newChosenCampaign = chosenCampaign;

        const existChosenCampaign = chosenCampaign.find(
            (item) => item.campaignId === campaign.campaignId,
        );
        if (existChosenCampaign) {
            newChosenCampaign = chosenCampaign.filter(
                (item) => item.campaignId !== campaign.campaignId,
            );
        } else {
            newChosenCampaign.push(campaign);
        }
        setChosenCampaign(newChosenCampaign);
        changeChosenServiceBooking(newChosenCampaign);
    };

    const handleGotoCampaignDetail = (campaign: CampaignType) => {
        setCampaignDetailSelected(campaign);
        gotoCampaignDetail();
    };

    return step === 4 ? (
        <ListCampaignScreen
            handleGotoCampaignDetail={(campaign: CampaignType) =>
                handleGotoCampaignDetail(campaign)
            }
            phone={phone}
            salonId={salonId}
            chosenService={chosenService}
            onClickChosenCampaign={(campaign: CampaignType) =>
                onClickSelectCampaign(campaign)
            }
            chosenCampaign={chosenCampaign}
            listCampaign={listCampaign}
            setListCampaign={(list: ListCampaignType) => setListCampaign(list)}
            setChosenCampaign={(list: CampaignType[]) =>
                setChosenCampaign(list)
            }
            tabs={tabs}
            setTabs={(tab: string) => setTabs(tab)}
        />
    ) : (
        <CampaignDetailScreen
            campaignDetailSelected={campaignDetailSelected}
            onClickChosenCampaign={(campaign: CampaignType) => {
                const existChosenCampaign = chosenCampaign.find(
                    (item) => item.campaignId === campaign.campaignId,
                );
                if (!existChosenCampaign) onClickSelectCampaign(campaign);
                else changeChosenServiceBooking(chosenCampaign);
            }}
            changeChosenServiceBooking={(listService: IService[]) =>
                changeChosenServiceBooking(chosenCampaign, listService)
            }
            chosenService={chosenService}
            listAllServices={listAllServices}
            handleGoToCampaignScreen={() => handleGoToCampaignScreen()}
            salonId={salonId}
            tabs={tabs}
        />
    );
};

export default CampaignScreen;
