import React, { FC } from 'react';
import RedirectLink from 'components/layout/redirectLink';

const LayoutHeadTitleMoyo: FC<{ data: any }> = ({ data }) => {
    return (
        <RedirectLink redirect={data.linkViewAll}>
            <div className="layout__head ">
                <div className='m-auto max-md:px-7'>
                    {data.title && (
                        <div className="text-[20px] max-md:text-base font-bold uppercase pl-2 text-[#20489B] relative border-l-[6px] border-[#EF4E24]">
                            {data.title}
                        </div>
                    )}
                    {data.subTitle && (
                        <div className="text-sm md:text-lg font-normal mt-0.5">
                            {data.subTitle}
                        </div>
                    )}
                </div>
            </div>
        </RedirectLink>
    );
};

export default LayoutHeadTitleMoyo;
