import React, { FC, useRef } from 'react';
import { Skeleton } from 'antd';
import Swiper from 'react-id-swiper';
import ItemServices from './ItemServices';
import { IListService } from '../../types/Service';

const paramsSwiper: {
    spaceBetween: number;
    slidesPerView: number | 'auto' | undefined;
    freeMode: boolean;
} = {
    spaceBetween: 15,
    slidesPerView: 1.3,
    freeMode: true,
};

const CategoriesServicesSwiper: FC<{
    isLoading: boolean;
    item: IListService;
    selected: any;
    onClickDetailService: Function;
    onSelectService: Function;
    trackingData: any;
    userId: any;
    isSearchResult: boolean;
    layoutDefault: boolean;
    gotoCampaignDetail: Function;
}> = ({
    isLoading,
    item,
    onClickDetailService,
    selected,
    onSelectService,
    trackingData,
    userId,
    isSearchResult,
    layoutDefault,
    gotoCampaignDetail,
}) => {
    const swiperRef = useRef<any>(null);

    return (
        <>
            {isLoading ? (
                <div className="service">
                    <Skeleton paragraph={{ rows: 3, width: '100%' }} />
                </div>
            ) : (
                <div className="service bg-white p-2.5 pt-[15px] mb-[15px]">
                    {!isSearchResult && (
                        <div className="service__category text-[#20489B] flex flex-wrap items-baseline gap-y-2.5 pb-1">
                            <div className="text-base font-semibold pr-2.5">
                                {item.categoryName}
                            </div>
                            <div className="text-sm font-medium">
                                {item.title}
                            </div>
                        </div>
                    )}
                    <div className="">
                        {item.serviceGroups?.length ? (
                            <Swiper ref={swiperRef} {...paramsSwiper}>
                                {item.serviceGroups.map((serviceGroup) => {
                                    return (
                                        <div
                                            className="!h-auto my-2"
                                            key={serviceGroup.groupId}
                                        >
                                            <ItemServices
                                                serviceGroup={serviceGroup}
                                                chosenService={
                                                    selected.chosenService
                                                }
                                                onClickDetailService={
                                                    onClickDetailService
                                                }
                                                onSelectService={
                                                    onSelectService
                                                }
                                                selected={selected}
                                                trackingData={trackingData}
                                                userId={userId}
                                                layoutDefault={layoutDefault}
                                                gotoCampaignDetail={gotoCampaignDetail}
                                            />
                                        </div>
                                    );
                                })}
                            </Swiper>
                        ) : null}
                    </div>
                </div>
            )}
        </>
    );
};

export default CategoriesServicesSwiper;
