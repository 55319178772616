import React, { FC } from 'react';
import TrackingConst from 'config/TrackingConst';
import API from 'repository';
import { connect } from 'react-redux';
import useWindowSize from 'hooks/useWindowSize';
import LayoutHeadTitleMoyo from '../title';
import '../../index.scss';
import { Link } from 'react-router-dom';


const MoyoServiceLayout: React.FC<{
    data: any;
    trackingData: any;
}> = ({ data, trackingData }) => {
    const size: any = useWindowSize();
    const isMobile = size.width < 768;

    const postTracking = async (eventId: string) => {
        if (!eventId) return;
        try {
            const dataTracking = {
                pageId: TrackingConst.Pages.HOME_PAGE,
                screenId: TrackingConst.Pages.HOME_PAGE,
                eventId,
                tokenKey: trackingData.token,
                source: trackingData.source,
                utmSource: trackingData.utmSource,
                data: {
                    phone: trackingData.phoneNumber || '',
                    customerId: trackingData.customerId || 0,
                },
            };
            await API.postTrackingDynamic(dataTracking);
        } catch (e) {
            console.log(e.message);
        }
    };
    const createMarkup = (htmlCode: string) => {
        return { __html: htmlCode };
    };
    const isEven = (id: number) => {
        if (id === 6) return
        return id % 2 === 0 ? "ml-auto" : "mr-auto";
    };

    console.log(data, "data")
    return (
        <div className="layout layout-service">
            <div className="container ">
                <LayoutHeadTitleMoyo data={data} />
                {data.list && !isMobile &&
                    data.list.length > 0 &&
                    <div className='grid grid-cols-3  gap-4'>
                        {data.list.slice(0, 3).map((item: any, index: number) => {
                            return (
                                <div className='cursor-pointer max-w-[300px]'>
                                    <Link
                                        to={item.redirectLink}
                                    >
                                        <img
                                            className=''
                                            src={
                                                isMobile &&
                                                    item.linkImageMb
                                                    ? item.linkImageMb
                                                    : item.linkImage
                                            }
                                            alt=""
                                        />
                                        <div className='text-left leading-3 pt-2'>
                                            <span className='font-semibold text-base text-black'>{item.title}</span>
                                            <br />
                                            <span className='text-sm text-[#767676]'>{item.subTitle}</span>
                                            <br />
                                            {item.subTitle2 &&
                                                <span className='text-sm text-[#767676]'>{item.subTitle2}</span>
                                            }
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                }

                {data.list && !isMobile &&
                    data.list.length > 0 &&
                    <div className='grid grid-cols-2 gap-4 mt-4'>
                        {data.list.slice(3).map((item: any, index: number) => {
                            return (
                                <div
                                    className={`${isEven(index)} cursor-pointer max-w-[300px] `}
                                >
                                    <Link
                                        to={item.redirectLink}
                                    >
                                        <img
                                            className=''
                                            src={
                                                isMobile &&
                                                    item.linkImageMb
                                                    ? item.linkImageMb
                                                    : item.linkImage
                                            }
                                            alt=""
                                        />

                                        <div className='text-left leading-3 pt-2'>
                                            <span className='font-semibold text-base text-black'>{item.title}</span>
                                            <br />
                                            <span className='text-sm text-[#767676]'>{item.subTitle}</span>
                                            <br />
                                            {item.subTitle2 &&
                                                <span className='text-sm text-[#767676]'>{item.subTitle2}</span>
                                            }
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                }

                {data.list && isMobile &&
                    data.list.length > 0 &&
                    <div className='grid grid-cols-2 gap-4'>
                        {data.list.map((item: any, index: number) => {
                            return (
                                <div
                                    className='cursor-pointer max-w-[300px] '
                                >
                                    <Link
                                        to={item.redirectLink}
                                    >
                                        <img
                                            className=''
                                            src={
                                                isMobile &&
                                                    item.linkImageMb
                                                    ? item.linkImageMb
                                                    : item.linkImage
                                            }
                                            alt=""
                                        />

                                        <div className='text-left leading-3 pt-2'>
                                            <span className='font-semibold text-base text-black'>{item.title}</span>
                                            <br />
                                            <span className='text-sm text-[#767676]'>{item.subTitle}</span>
                                            <br />
                                            {item.subTitle2 &&
                                                <span className='text-sm text-[#767676]'>{item.subTitle2}</span>
                                            }
                                        </div>
                                    </Link>

                                </div>
                            )
                        })}
                    </div>
                }
            </div>
        </div >
    );
};

const mapStateToProps = (state: any) => {
    return {
        trackingData: state.trackingData,
    };
};

export default connect(mapStateToProps)(MoyoServiceLayout);
